<template>
    <div class="container">

        <div class="row d-flex justify-content-center align-content-center">
            <div class="col-12">
                <b-button size="sm" class="" @click="goback" style="border-radius: 6px;" variant="dark"><strong>Go Back</strong></b-button>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-10 mt-5" v-if="stage === 0">
                <b-card
                    border-variant="secondary"
                    header="true"
                    header-border-variant="secondary"
                    align="center">

                    <div slot="header" class="dd-flex justify-content-between align-items-center">
                        <!--                        <b-button size="sm" variant="light" @click="stage--" v-b-tooltip.top title="Go Back" pill><b-icon-chevron-double-left variant="primary" font-scale="1" style="cursor: pointer; font-weight: bold;"></b-icon-chevron-double-left></b-button>--->
                        <p class="d-inline-block align-middle mb-0 ml-2">Product Configuration</p>
                    </div>

                    <b-card-text>Choose A Product Type <b-icon-info-circle-fill variant="primary" style="cursor: pointer;"></b-icon-info-circle-fill></b-card-text>
                    <div class="row">
                        <div style="padding: 1%" v-for="(product,index) in products" :key="index" class="col-6 col-md-6">
                            <b-button @click="$router.push({query: {product: product._id,client_id: $route.query.client_id}}); selected = false" size="sm"  style="height: 70px !important; min-width: 130px !important;" variant="secondary"><strong>{{product.Name}}</strong></b-button>
                        </div>
                    </div>
                    <div class="row d-flex mt-5 justify-content-center">
                        <b-button size="sm" :disabled="selected" style="border-radius: 8px" @click="stage++; selected = true" variant="primary"><strong>Select Product Type</strong></b-button>
                    </div>
                </b-card>
            </div>
            <div class="col-6 mt-5" v-if="stage === 1">
                <b-card
                    border-variant="secondary"
                    header="Product Configuration"
                    header-border-variant="secondary"
                    align="center">

                    <b-card-text>Begin configuring your product by clicking the button below. <b-icon-info-circle-fill variant="primary" style="cursor: help;"></b-icon-info-circle-fill></b-card-text>
                    <b-button size="sm" style="border-radius: 8px" @click="stage++" variant="primary"><strong>Select Your Plan Type</strong></b-button>
                </b-card>
            </div>
            <div class="col-10 mt-5" v-if="stage === 2">
                <b-card
                    border-variant="secondary"
                    header="true"
                    header-border-variant="secondary"
                    align="center">

                    <div slot="header" class="dd-flex justify-content-between align-items-center">
                        <b-button size="sm" variant="light" @click="stage--" v-b-tooltip.top title="Go Back" pill><b-icon-chevron-double-left variant="primary" font-scale="1" style="cursor: pointer; font-weight: bold;"></b-icon-chevron-double-left></b-button>
                        <p class="d-inline-block align-middle mb-0 ml-2">Product Configuration</p>
                    </div>

                    <b-card-text>Choose A Plan Type <b-icon-info-circle-fill variant="primary" style="cursor: pointer;"></b-icon-info-circle-fill></b-card-text>
                    <div class="row">
                        <div class="col-6">
                            <b-button size="sm" :pressed.sync="dataUsage" :disabled="clickUsage" style="height: 70px !important;" variant="secondary"><strong>Data Usage</strong></b-button>
                        </div>
                        <div class="col-6">
                            <b-button size="sm" :pressed.sync="clickUsage" :disabled="dataUsage" style="height: 70px !important;" variant="secondary"><strong>Click Usage</strong></b-button>
                        </div>
                    </div>
                    <div class="row d-flex mt-5 justify-content-center">
                        <b-button size="sm" :disabled="!dataUsage && !clickUsage" style="border-radius: 8px" @click="stage++" variant="primary"><strong>Select Proxy Type</strong></b-button>
                    </div>
                </b-card>
            </div>
            <div class="col-10 mt-5" v-if="stage === 3">
                <b-card
                    border-variant="secondary"
                    header="true"
                    header-border-variant="secondary"
                    align="center">

                    <div slot="header" class="dd-flex justify-content-between align-items-center">
                        <b-button size="sm" variant="light" @click="stage--" v-b-tooltip.top title="Go Back" pill><b-icon-chevron-double-left variant="primary" font-scale="1" style="cursor: pointer; font-weight: bold;"></b-icon-chevron-double-left></b-button>
                        <p class="d-inline-block align-middle mb-0 ml-2">Product Configuration</p>
                    </div>

                    <b-card-text>Choose Your Proxy Type <b-icon-info-circle-fill variant="primary" style="cursor: pointer;"></b-icon-info-circle-fill></b-card-text>

                    <div class="row">
                        <div class="col-6">
                            <b-button size="sm" :pressed.sync="ResiCity" :disabled="ResiState || DataCenter || Mobile" style="height: 90px !important;" variant="secondary"><strong>Residential City</strong></b-button>
                        </div>
                        <div class="col-6">
                            <b-button size="sm" :pressed.sync="ResiState" :disabled="ResiCity || DataCenter || Mobile" style="height: 90px !important;" variant="secondary"><strong>Residential State</strong></b-button>
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                        <div class="col-6">
                            <b-button size="sm" :pressed.sync="DataCenter" :disabled="ResiCity || ResiState || Mobile" style="height: 90px !important; width: 130px;" variant="secondary"><strong>Data Center</strong></b-button>
                        </div>
                        <div class="col-6">
                            <b-button size="sm" :pressed.sync="Mobile" :disabled="ResiCity || ResiState || DataCenter" style="height: 90px !important; width: 130px" variant="secondary"><strong>Mobile</strong></b-button>
                        </div>
                    </div>
                    <b-button size="sm" v-if="ResiCity || ResiState || DataCenter || Mobile" @click="stage++" style="border-radius: 8px" variant="primary"><strong>Select A Plan Size</strong></b-button>
                </b-card>
            </div>
            <div class="col-10 mt-5" v-if="stage === 4">
                <b-card
                    border-variant="secondary"
                    header="true"
                    header-border-variant="secondary"
                    align="center">

                    <div slot="header" class="dd-flex justify-content-between align-items-center">
                        <b-button size="sm" variant="light" @click="stage--" v-b-tooltip.top title="Go Back" pill><b-icon-chevron-double-left variant="primary" font-scale="1" style="cursor: pointer; font-weight: bold;"></b-icon-chevron-double-left></b-button>
                        <p class="d-inline-block align-middle mb-0 ml-2">Product Configuration</p>
                    </div>

                    <b-card-text>
                        <div class="row d-flex justify-content-center">
                            <p>Choose Your Plan Size</p>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-2 mr-3" v-for="(selPrx, idx) in selectedProxy" :key="selPrx._id">
                                <div v-if="planDriven === 'data'" class="row d-flex justify-content-center" style="background-color: #fcfcfa;"><strong>{{ selPrx.BandwidthName }}</strong></div>
                                <div v-if="planDriven === 'data'" class="row d-flex justify-content-center mt-1" style="font-size: 19px;">{{ selPrx.Bandwidth / 1000000000 }}G</div>

                                <div v-if="planDriven === 'click'" class="row d-flex justify-content-center" style="background-color: #fcfcfa;"><strong>{{selPrx.NumberOfClicks|convert}}</strong></div>
<!--                                <div v-if="planDriven === 'click'" class="row d-flex justify-content-center mt-1" style="font-size: 19px;">{{ selPrx.Bandwidth / 1000000000 }}G</div>-->

                                <div class="row d-flex justify-content-center">
                                    <div class="col-12 col-md-12 mt-1 mb-2">
                                        <b-icon-plus-circle font-scale="1.5" variant="primary" style="cursor: pointer; font-weight: bold;" v-b-toggle="'collapse-' + idx"></b-icon-plus-circle>
                                    </div>
                                    <b-collapse :id="'collapse-' + idx">
                                        <div class="col-12 col-md-12">
                                            <h6><strong>Domains</strong></h6>
                                        </div>
                                        <div class="col-12 col-md-12">
                                            {{ selPrx.NumberOfDomains }}
                                        </div>
                                    </b-collapse>
                                </div>
                                <div class="row d-flex justify-content-center">
                                    <b-button size="sm" class="btn btn-block" @click="setPlan(selPrx._id)" style="border-radius: 0px !important;">Select</b-button>
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </b-card>
            </div>
            <div class="col-10 mt-5" v-if="stage === 5">
                <b-card
                    border-variant="secondary"
                    header="true"
                    header-border-variant="secondary"
                    align="center">

                    <div slot="header" class="dd-flex justify-content-between align-items-center">
                        <b-button size="sm" variant="light" @click="stage--" v-b-tooltip.top title="Go Back" pill><b-icon-chevron-double-left variant="primary" font-scale="1" style="cursor: pointer; font-weight: bold;"></b-icon-chevron-double-left></b-button>
                        <p class="d-inline-block align-middle mb-0 ml-2">Product Configuration</p>
                    </div>

                    <b-card-text>
                        <div class="row d-flex justify-content-center">
                            <p>Verify Plan Information</p>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div v-if="summary.client.Name" class="col-md-3">Client: {{summary.client.Name}}</div>
                                <div class="col-md-3">Product: {{summary.product[0].Name}}</div>
                                <div class="col-md-6">Date: {{new Date().toString().substr(0,15)}}</div>

                                <div class="col-md-3">Plan Type: {{summary.plan[0].Driven}}</div>
                                <div class="col-md-3">Proxy Type: {{summary.plan[0].ProxyType}}</div>
                                <div v-if="summary.plan[0].Driven" class="col-md-6">Plan Size: #Clicks - {{summary.plan[0].NumberOfClicks}}, #Domains - {{summary.plan[0].NumberOfDomains}}</div>
                                <div v-else class="col-md-4">Plan Size: </div>
                            </div>
                            <div style="margin-top:20px;" class="row d-flex justify-content-center">
                                <b-button @click="selectedPlan(summary.plan[0]._id,summary.product[0].Identifier)" variant="primary">Save Info</b-button>
                            </div>
                        </div>
                    </b-card-text>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
import { BIconPlusCircle, BIconInfoCircleFill, BIconChevronDoubleLeft } from 'bootstrap-vue'
import axios from "axios";
import swal from "sweetalert";

export default {
    name: "ProductConfig",
    components: {
        BIconPlusCircle,
        BIconInfoCircleFill,
        BIconChevronDoubleLeft
    },
    data() {
        return {
            plans: [],
            summary:{
                client:{Name: ''}
            },
            //client:{},
            selected: true,
            selectedPlans: [],
            selectedProxy: [],
            planDriven: '',
            planProxy: '',
            plan: '',
            products:[],
            stage: 0,
            dataUsage: false,
            clickUsage: false,
            ResiCity: false,
            ResiState: false,
            DataCenter: false,
            Mobile: false,
        }
    },
    created() {
        this.$root.preloader = false;
        this.loadPlans();
        this.loadProducts();
    },
    filters: {
        convert: function(value) {
            if (value < 1000000) {
                return value / 1000 + 'K'
            } else {
                return value / 1000000 + 'M'
            }
        }
    },
    watch: {
        dataUsage(value) {
          if (value) {
              this.loadDataPlans();
          }
        },
        clickUsage(value) {
          if (value) {
              this.loadClickPlans();
          }
        },
        ResiCity(value) {
          if(value) {
              this.loadProxyResiCity();
          }
        },
        ResiState(value) {
          if(value) {
              this.loadProxyResiState();
          }
        },
        DataCenter(value) {
          if(value) {
              this.loadProxyDataCenter();
          }
        },
        Mobile(value) {
          if(value) {
              this.loadProxyMobile();
          }
        }
    },
    methods: {
        goback:function(){
            return this.$router.push({path: '/plan/listing/',query: {client_id: this.$route.query.client_id}})
        },
        loadDataPlans: function() {
            this.selectedPlans = []

            this.plans.filter( (plan) => {
                if (plan.Driven === 'data' && plan.ProductId === this.$route.query.product) {
                    this.planDriven = 'data'
                    this.selectedPlans.push(plan)
                }
            })
        },
        loadProxyResiCity: function() {
            this.selectedProxy = []
            this.plans.filter( (proxyPlan) => {
                if (proxyPlan.ProxyType === 'ResidentialCity' && proxyPlan.Driven === this.planDriven && proxyPlan.ProductId === this.$route.query.product) {
                    this.planProxy = 'ResidentialCity'
                    this.selectedProxy.push(proxyPlan)
                }
            })
        },
        loadProxyResiState: function() {
            this.selectedProxy = []

            this.plans.filter( (proxyPlan) => {
                if (proxyPlan.ProxyType === 'ResidentialState' && proxyPlan.Driven === this.planDriven && proxyPlan.ProductId === this.$route.query.product) {
                    this.planProxy = 'ResidentialState'
                    this.selectedProxy.push(proxyPlan)
                }
            })
        },
        loadProxyDataCenter: function() {
            this.selectedProxy = []

            this.plans.filter( (proxyPlan) => {
                if (proxyPlan.ProxyType === 'Data Center' && proxyPlan.Driven === this.planDriven && proxyPlan.ProductId === this.$route.query.product) {
                    this.planProxy = 'Data Center'
                    this.selectedProxy.push(proxyPlan)
                }
            })
        },
        loadProxyMobile: function() {
            this.selectedProxy = []

            this.plans.filter( (proxyPlan) => {
                if (proxyPlan.ProxyType === 'Mobile' && proxyPlan.Driven === this.planDriven && proxyPlan.ProductId === this.$route.query.product) {
                    this.planProxy = 'Mobile'
                    this.selectedProxy.push(proxyPlan)
                }
            })
        },
        loadClickPlans: function() {
          this.selectedPlans = []

          this.plans.filter( (plan) => {
              if (plan.Driven === 'click' && plan.ProductId === this.$route.query.product) {
                  this.planDriven = 'click'
                  this.selectedPlans.push(plan)
              }
          })
        },
        loadPlans: function() {
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/products/plans`, {params:request}).then(function(resp){
                //Store the stats
                this.plans = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        async setPlan(value){
           var val = await swal({title:this.planProxy + " Plan", text:"Add plan to client Account?", icon:"info", buttons:['No','Yes']});
           if(val){
               this.plan = value;
               this.stage++;
              await this.summaryInfo();
           }
        },
        async summaryInfo(){
          try {
              this.summary.plan = this.plans.filter(plan => plan._id === this.plan);
              this.summary.product = this.products.filter(product => product._id === this.$route.query.product);

              var client = await axios.get(`${this.$root.serverUrl}/admin/clients/${this.$route.query.client_id}`);
              this.summary.client = client.data.data;
              //this.client = client.data;
          }  catch(err){
              console.log(err)
          }
        },
        selectedPlan(value, product) {
            swal({title:this.planProxy + " Plan", text:"Add plan to client Account?", icon:"info", buttons:['No','Yes']}).then(function(val) {
                if (!val) return;
                this.$root.preloader = true;


                axios.put(`${this.$root.serverUrl}/admin/clients/plan`, { client_id: this.$route.query.client_id, plan_id: value , product_id: this.$route.query.product,
                    product_type:product} ).then(function (resp) {
                    //Process the results
                    this.$root.preloader = false;
                    if (resp.data && !resp.data.error) {
                        //Saved successfully, navigate to Plans List
                        this.$router.push({ path: '/plan/listing', query: { client_id: this.$route.query.client_id } })

                        // swal({
                        //     title: this.planProxy + " Plan",
                        //     text: "Your selected Plan was Added.",
                        //     icon: "success"
                        // }).then(function () {
                        //
                        // }.bind(this));
                    } else {
                        //Handle errors
                        swal({title: this.planProxy + " Plan", text: "Unable to complete your request", icon: "error"});
                    }
                }.bind(this))
                    .catch(function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({title: "Error", text: this.errormsg, icon: "error"});
                    }.bind(this))
            }.bind(this));
        },
        async loadProducts() {
            try {
                const data = await axios.get(`${this.$root.serverUrl}/admin/products/`);
                this.products = data.data.data;
            }catch(err){
                console.log(err);
                swal({title:"Error", text: err, icon: "error"});
            }
        }
    }
}
</script>

<style scoped>
    .b-icon.bi:focus {
        outline: none !important;
    }
</style>
